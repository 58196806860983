import { defineStore } from 'pinia';
import { reactive, ref, watch } from 'vue';
import dayjs from 'dayjs';
import { useEventBus } from '@vueuse/core';
import { utilitiesKey } from '@utilities/services/events';
import { FormUploadPayload } from '@utilities/types/requests';
import { MODAL_TYPES, MODAL_VOID } from '../constants';
import utilitiesApi from '../services/utilitiesApi';
import {
  FormId,
  UtilitiesForm,
  UtilitiesQuery,
  UtilitiesStats,
} from '../types';
import { useHelpers } from '~/composables/useHelpers';

const dateFormat: string = 'DD/MM/YY';

const setAddressString = (data: UtilitiesForm) => {
  if (typeof data === 'undefined' || typeof data.address === 'undefined') {
    return;
  }

  data.addressString = '';

  if (data.address.line1) {
    data.addressString = data.address.line1.trim() + ', ';
  }

  if (data.address.city) {
    data.addressString += data.address.city.trim() + ', ';
  }

  if (data.address.postcode) {
    data.addressString += `${data.address.postcode}`;
  }

  return data;
};

const setDateStrings = (data: UtilitiesForm) => {
  data.createdAtString = data.createdAt
    ? dayjs(data.createdAt).format(dateFormat)
    : '';
  data.endDateString = data.endDate
    ? dayjs(data.endDate).format(dateFormat)
    : '';
  data.startDateString = data.startDate
    ? dayjs(data.startDate).format(dateFormat)
    : '';
  return data;
};

const mapFormData = (form: UtilitiesForm) => {
  // if (form.recordTypeName === 'UniHomes Online') {
  //   form.online = true;
  // }
  //
  // if (form.billingType === 'Landlord/Agent') {
  //   form.agentBilling = true;
  // }

  form = setDateStrings(form);

  if (form.address && form.address.street) {
    const streetComponents = form.address.street.split(' ');
    form.address.houseNo = streetComponents.shift();
    form.address.street = streetComponents.join(' ');
  }

  form = setAddressString(form);

  if (form.source?.value === 'utility_account') {
    form.online = true;
  }

  if (Array.isArray(form.tenants)) {
    form.tenantsSigned = form.tenants
      .map((tenant) => tenant.signed)
      .filter(Boolean).length;
  }

  form.tenantCount = form.tenants.length;
  form.signaturesRemaining = form.tenantCount - form.tenantsSigned;

  return form;
};

export const useUtilityStore = defineStore('utilities', () => {
  const { updateRouteQueryParams } = useHelpers();
  const { user } = useAuth();
  const bus = useEventBus(utilitiesKey);

  const defaultQuery = {
    filter: {
      from: '',
      search: '',
      status: '',
      to: '',
    },
    page: 1,
    perPage: 15,
    sort: '-created_at',
  };

  const defaultStats = {
    draft: 0,
    sent: 0,
    pending: 0,
    processing: 0,
    approved: 0,
    active: 0,
    cancelled: 0,
    total: 0,
  };

  const stats = reactive<UtilitiesStats>({ ...defaultStats });
  const query = reactive<UtilitiesQuery>({ ...defaultQuery });
  const loading = reactive({
    stats: false,
    table: false,
  });
  const forms = ref<UtilitiesForm[]>([]);
  const meta = reactive({ currentPage: 0, totalPages: 0, lettingSeasons: [] });
  const modal = ref<{ open: string; data?: object | null }>({
    open: '',
    data: {},
  });

  // Getters
  const queryParams = computed(() => {
    return {
      'filter[from]': query.filter.from,
      'filter[search]': query.filter.search,
      'filter[status]': query.filter.status,
      'filter[to]': query.filter.to,
      page: query.page,
      perPage: query.perPage,
      sort: query.sort,
    };
  });

  const lettingSeason = computed(() => {
    return `${query.filter.from}|${query.filter.to}`;
  });

  // Actions
  // eslint-disable-next-line require-await
  async function setDefaultLettingSeason() {
    const now = dayjs();
    const thisOctober = dayjs().set('month', 9).startOf('month');
    const lastOctober = thisOctober.subtract(1, 'year');
    const diffThisOctober = now.diff(thisOctober, 'days');

    if (diffThisOctober < 0) {
      query.filter.from = `${lastOctober.format('YYYY-MM-DD')}`;
      query.filter.to = `${lastOctober.add(1, 'year').year()}-09-30`;
    } else {
      query.filter.from = `${thisOctober.format('YYYY-MM-DD')}`;
      query.filter.to = `${thisOctober.add(1, 'year').year()}-09-30`;
    }
  }

  function resetFilters() {
    Object.assign(query, { ...defaultQuery });
  }

  function sortBy(field: string): void {
    if (query.sort === field) {
      query.sort = `-${field}`;
      return;
    }

    query.sort = field;
  }

  async function getStats() {
    try {
      loading.stats = true;
      const { data } = await utilitiesApi.accounts.stats({
        from: query.filter.from,
        to: query.filter.to,
      });

      Object.assign(stats, data.value?.data);

      loading.stats = false;
    } catch (e) {
      console.error(e);
      loading.stats = false;
    }
  }

  async function getAccounts(dontUpdateRouteQueryParams: boolean = false) {
    try {
      loading.table = true;
      const { data } = await utilitiesApi.accounts.get(unref(queryParams));

      if (data.value) {
        forms.value = data.value.data.map((form) => mapFormData(form));
        Object.assign(meta, data.value?.meta);
      }

      loading.table = false;

      if (dontUpdateRouteQueryParams) {
        return;
      }
      updateRouteQueryParams(unref(queryParams));
    } catch (e) {
      console.error(e);
      loading.table = false;
    }
  }

  async function getAccount(id: FormId) {
    try {
      const { data } = await utilitiesApi.accounts.show(id);

      return data.value;
    } catch (e) {
      console.error(e);
    }
  }

  async function deleteAccount(ids: FormId[]) {
    try {
      await utilitiesApi.accounts.destroy({ forms: ids });
    } catch (e) {
      console.error(e);
    }
  }

  async function uploadForms(payload: { forms: FormUploadPayload[] }) {
    try {
      await utilitiesApi.orders.upload(payload);
    } catch (e) {
      console.error(e);
    }
  }

  function openModal(type: string, data = null) {
    if (!MODAL_TYPES.includes(type)) {
      throw new Error(`Invalid modal type: ${type}`);
    }

    if (modal.value.open === type) {
      return;
    }

    modal.value = {
      open: type,
      data,
    };
  }

  function closeModal(checkType = '') {
    if (checkType && !modalIsOpen(checkType)) {
      return;
    }

    modal.value = {
      open: '',
      data: {},
    };
  }

  function modalIsOpen(type: string) {
    return modal.value.open === type;
  }

  async function refresh(dontUpdateRouteQueryParams: boolean = false) {
    await getStats();
    await getAccounts(dontUpdateRouteQueryParams);
  }

  // Events
  bus.on(async (event, payload) => {
    if (event === 'void-order') {
      openModal(MODAL_VOID, payload);
    }

    if (event === 'refresh-table') {
      await refresh();
    }
  });

  // Watchers
  watch(() => query, getAccounts, { deep: true, immediate: false });
  watch([() => query.filter.from, () => query.filter.to], getStats);

  return {
    // State
    forms,
    lettingSeason,
    loading,
    meta,
    modal,
    query,
    queryParams,
    stats,
    user,
    // Actions
    closeModal,
    deleteAccount,
    getAccount,
    getAccounts,
    getStats,
    modalIsOpen,
    openModal,
    refresh,
    resetFilters,
    uploadForms,
    setDefaultLettingSeason,
    sortBy,
  };
});
